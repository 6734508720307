<template>
  <section
    style="background-color: #FAFAFA; height: 100%"
    id="admin"
    class="pa-0"
  >
    <!-- Start alert button --->
    <v-btn
      elevation="10"
      :class="$vuetify.breakpoint.smAndUp ? 'quick_add_wrapper':'quick_add_wrapper_mobile'"
      fixed
      color="red"
      large
      rounded
      @click="add_outage = true">
      <p
        v-if="$vuetify.breakpoint.smAndUp"
        class="alert_text"
        v-text="alert_text"
      />
      <v-icon color="white">
        mdi-alert
      </v-icon>
    </v-btn>
    <!-- End alert button --->
    <!-- Start quick add report -->
    <quick-add
      :bActive="add_outage"
      @close="add_outage = false"
    />
    <!-- End quick add reprort-->
    <admin-menu />
    <div
      :class="this.$vuetify.breakpoint.smAndUp ? 'section_division':'section_division_mobile'">
      <v-container
        class="view_conatiner">
        <v-slide-y-transition>
          <router-view />
        </v-slide-y-transition>        
      </v-container>
    </div>
  </section>
</template>

<script>
  export default {
    components: {
      AdminMenu: () => import('@/components/sections/Admin/SideMenu'),
      QuickAdd: () => import('@/components/sections/Admin/ConfirmQuickAdd')
    },
    data: () => ({
      add_outage: false,
      alert_text: 'Report Outage',

    }),
  methods: {
    post_outage () {
      this.bLoadOutage = true
    }
  },
    computed: {
    }
  }
</script>
<style>
.section_division_mobile {
  margin-top: 100px
}
.view_conatiner {
  padding: 0%
}
.section_division {
  margin-left: 22%;
}
.section_title {
  font-family: 'Open sans', sans-serif;
  font-size: 19px;
  color: #000000
}
.content_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  color: #000000
}
.table_header_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #000000
}
.quick_add_wrapper {
  min-height: 65px;
  min-width: 120px;
  z-index: 100;
  bottom: 0;
  right: 20px;
  margin-bottom: 2%
}
.quick_add_wrapper_mobile {
  min-height: 50px;
  min-width: 90px;
  z-index: 100;
  bottom: 0;
  right: 10px;
  margin-bottom: 2%
}
.alert_text {
  font-weight: bold;
  margin: 14px;
  color: white;
  font-family: 'Open sans', sans-serif;
}

</style>
